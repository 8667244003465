import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Prism from 'prismjs';
import styles from './Content.module.scss';
import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/components/prism-bash.min.js';
import 'prismjs/components/prism-javascript.min.js';
import 'prismjs/components/prism-jsx.min.js';
import 'prismjs/components/prism-css.min.js';
import 'prismjs/components/prism-scss.min.js';

const cx = classNames.bind(styles);

class HtmlContent extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }

  render() {
    const { html } = this.props;

    const markup = {
      __html: html,
    };

    return <div className={cx('content')} dangerouslySetInnerHTML={markup} />;
  }
}

HtmlContent.propTypes = {
  html: PropTypes.string.isRequired,
};

export default HtmlContent;
