import React from 'react';
import classNames from 'classnames/bind';
import { FaGithub, FaEnvelopeSquare } from 'react-icons/fa';

import Image from 'src/components/common/Image';
import styles from './Author.module.scss';

const cx = classNames.bind(styles);

const Author = ({ image }) => {
  const imageStyle = {
    width: '4rem',
    height: '4rem',
    borderRadius: '100%',
  };
  return (
    <div className={cx('author')}>
      <div className={cx('image')}>
        <Image image={image} style={imageStyle} />
      </div>
      <div className={cx('content')}>
        <div className={cx('name')}>
          <h2>yunheur</h2>
        </div>
        <div className={cx('intro')}>
          <p>한 걸음씩 걸어 나아가는 중인 웹 개발자입니다.</p>
        </div>
        <div className={cx('social')}>
          <ul className={cx('social-list')}>
            <li className={cx('social-item')}>
              <a href="https://github.com/yunheur">
                <FaGithub />
              </a>
            </li>
            <li className={cx('social-item')}>
              <a href="mailto:yunheur@gmail.com">
                <FaEnvelopeSquare />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Author;
