import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Toc.module.scss';

const cx = classNames.bind(styles);

class Toc extends React.Component {
  scrollTimer = null;

  scrollInterval = 100;

  componentDidMount() {
    window.addEventListener('scroll', this.scrollHandle);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandle);
  }

  scrollHandle = () => {
    clearTimeout(this.scrollTimer);
    this.scrollTimer = setTimeout(() => {
      const { toc } = this.props;

      const viewportBottom =
        document.documentElement.scrollTop +
        document.documentElement.clientHeight;

      toc.forEach(item => {
        const hdItemEl = document.getElementById(item.id);
        const tocItemEl = document.getElementById(
          `toc-item-${item.level}-${item.index}`
        );
        // eslint-disable-next-line no-unused-expressions
        hdItemEl.offsetTop <= viewportBottom
          ? tocItemEl.classList.add(cx('active'))
          : tocItemEl.classList.remove(cx('active'));
      });
    }, this.scrollInterval);
  };

  render() {
    const { toc } = this.props;
    return (
      <div className={cx('toc')}>
        <div className={cx('title')}>
          <h2>목차</h2>
        </div>
        <ol className={cx('list')}>
          {toc.map(data => (
            <li
              id={`toc-item-${data.level}-${data.index}`}
              key={data.id}
              className={cx(`item${data.level}`)}
            >
              <a href={`#${data.id}`}>{data.text}</a>
            </li>
          ))}
        </ol>
      </div>
    );
  }
}

Toc.propTypes = {
  toc: PropTypes.array,
};

export default Toc;
