import React from 'react';
import classNames from 'classnames/bind';
import styles from './PostWrapper.module.scss';

const cx = classNames.bind(styles);

const PostWrapper = ({ children }) => {
  return <div className={cx('post-wrapper')}>{children}</div>;
};

export default PostWrapper;
