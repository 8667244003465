import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { DiscussionEmbed } from 'disqus-react';
import { FaTimes } from 'react-icons/fa';
import marked from 'marked';

import PostWrapper from 'src/components/post/PostWrapper';
import DevPostInfo from 'src/components/post/dev/DevPostInfo';
import DevPostBody from 'src/components/post/dev/DevPostBody';
import Author from 'src/components/common/Author';
import Toc from 'src/components/common/Toc';

import styles from './DevPost.module.scss';

const cx = classNames.bind(styles);

/**
 * html과 toc 반환
 */
const getMdToData = markdown => {
  // 0 인덱스는 사용하지 않고 1~4 인덱스까지 h1~h4의 카운트로 사용
  const headerCnt = [-1, 0, 0, 0, 0];
  const toc = [];
  const renderer = new marked.Renderer();
  renderer.heading = (text, level) => {
    // eslint-disable-next-line no-plusplus
    const index = ++headerCnt[level];
    const id = `hd-bm-${level}-${index}`;
    if (level < 3) {
      toc.push({ id, level, index, text });
    }
    return `<h${level} id=${id}>${text}</h${level}>`;
  };
  const html = marked(markdown, {
    breaks: true, // 일반 엔터로 새 줄 입력
    sanitize: false, // 마크다운 내부 html 무시
    headerIds: false,
    renderer,
  });

  return { html, toc };
};

const onClickTocOpen = () => {
  document.getElementById('post-toc-open-btn').classList.add(cx('hide'));
  document.getElementById('post-toc').classList.remove(cx('hide'));
};

const onClickTocClose = () => {
  document.getElementById('post-toc').classList.add(cx('hide'));
  document.getElementById('post-toc-open-btn').classList.remove(cx('hide'));
};

const DevPost = ({
  blogTitle,
  id,
  title,
  date,
  image,
  tags,
  body,
  authorImage,
}) => {
  const disqusShortname = 'https-blog-yunheur-com';
  const disqusConfig = {
    identifier: id,
    title,
  };
  const { html, toc } = getMdToData(body);
  return (
    <PostWrapper>
      <div className={cx('post')}>
        <section>
          <DevPostInfo date={date} title={title} tags={tags} image={image} />
          <DevPostBody body={html} />
        </section>
        <section>
          <div className={cx('author')}>
            <Author image={authorImage} />
          </div>
        </section>
        {id && (
          <section className={cx('disqus')}>
            <DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
            />
          </section>
        )}
        <div
          id="post-toc-open-btn"
          className={cx('toc-open-btn')}
          onClick={onClickTocOpen}
        >
          TOC
        </div>
        <aside id="post-toc" className={cx('hide')}>
          <Toc toc={toc} />
          <div className={cx('close')} onClick={onClickTocClose}>
            <FaTimes size="24" />
          </div>
        </aside>
      </div>
    </PostWrapper>
  );
};

DevPost.propTypes = {
  blogTitle: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  image: PropTypes.object,
  tags: PropTypes.array,
  body: PropTypes.string.isRequired,
  authorImage: PropTypes.object,
};

export default DevPost;
