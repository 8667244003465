import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './Content.module.scss';

const cx = classNames.bind(styles);

const PreviewContent = ({ content }) => {
  return <div className={cx('content')}>{content}</div>;
};

PreviewContent.propTypes = {
  content: PropTypes.node.isRequired,
};

export default PreviewContent;
