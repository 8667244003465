import React from 'react';
import { graphql } from 'gatsby';
import PageTemplateContainer from 'src/containers/common/PageTemplateContainer';
import DevPost from 'src/components/post/dev/DevPost';
import Helmet from 'react-helmet';

const DevPage = ({ data }) => {
  const { markdownRemark: post, authorImage } = data;

  return (
    <PageTemplateContainer>
      {post.rawMarkdownBody && (
        <Helmet>
          <html lang="ko" />
          <title>
            {`${post.frontmatter.title} | ${data.site.siteMetadata.title}`}
          </title>
          <meta
            name="description"
            content={post.rawMarkdownBody.slice(0, 200)}
          />
          <meta
            name="google-site-verification"
            content="CHBdeXNOvzHYfS-eUHaDSNSxiHEAYcXUTNP6K3ddtmE"
          />
        </Helmet>
      )}
      <DevPost
        blogTitle={data.site.siteMetadata.title}
        id={post.id}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        image={post.frontmatter.image}
        tags={post.frontmatter.tags}
        body={post.rawMarkdownBody}
        authorImage={authorImage}
      />
    </PageTemplateContainer>
  );
};

export default DevPage;

export const pageQuery = graphql`
  query DevPageByID($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      rawMarkdownBody
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    authorImage: file(relativePath: { eq: "author.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
