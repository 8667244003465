import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import marked from 'marked';

import Prism from 'prismjs';
import styles from './Content.module.scss';
import 'prismjs/themes/prism-okaidia.css';
// http://prismjs.com/#languages-list 참조
import 'prismjs/components/prism-bash.min.js';
import 'prismjs/components/prism-javascript.min.js';
import 'prismjs/components/prism-jsx.min.js';
import 'prismjs/components/prism-css.min.js';
import 'prismjs/components/prism-scss.min.js';

const cx = classNames.bind(styles);

class MarkdownContent extends React.Component {
  state = {
    html: '',
  };

  constructor(props) {
    super(props);
    const { markdown } = props;
    this.state = {
      html: markdown
        ? marked(markdown, {
            breaks: true, // 일반 엔터로 새 줄 입력
            sanitize: true, // 마크다운 내부 html 무시
          })
        : '',
    };
  }

  componentDidMount() {
    Prism.highlightAll();
  }

  componentDidUpdate(prevProps, prevState) {
    // markdown 값이 변경되면 renderMarkdown을 호출합니다.
    if (prevProps.markdown !== this.props.markdown) {
      this.renderMarkdown();
    }
    // state가 바뀌면 코드 하이라이팅
    if (prevState.html !== this.state.html) {
      Prism.highlightAll();
    }
  }

  renderMarkdown = () => {
    const { markdown } = this.props;
    // 마크다운이 존재하지 않는다면 공백 처리
    if (!markdown) {
      this.setState({ html: '' });
      return;
    }
    this.setState({
      html: marked(markdown, {
        breaks: true, // 일반 엔터로 새 줄 입력
        sanitize: true, // 마크다운 내부 html 무시
      }),
    });
  };

  render() {
    const { html } = this.state;

    // React에서 html을 렌더링하려면 객체를 만들어 내부에
    // __html 값을 설정해야 합니다.
    const markup = {
      __html: html,
    };

    // 그리고 dangerouslySetInnerHTML 값에 해당 객체를 넣어 주면 됩니다.
    return <div className={cx('content')} dangerouslySetInnerHTML={markup} />;
  }
}

MarkdownContent.propTypes = {
  markdown: PropTypes.string.isRequired,
};

export default MarkdownContent;
