import React from 'react';
import classNames from 'classnames/bind';
import { HtmlContent } from 'src/components/common/Content';
import PropTypes from 'prop-types';
import styles from './DevPostBody.module.scss';

const cx = classNames.bind(styles);

const DevPostBody = ({ body }) => {
  return <div className={cx('post-body')}>{<HtmlContent html={body} />}</div>;
};

DevPostBody.propTypes = {
  body: PropTypes.string.isRequired,
};

export default DevPostBody;
